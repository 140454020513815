<template>
  <div class="yt-main">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>测评监控</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manage/exam/monitor' }">测评监控列表</el-breadcrumb-item>
        <el-breadcrumb-item>{{ examDetail.examName }}监控详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="yt-header">
      <div class="exam-detail-wrap">
        <div class="exam-detail">
          <div class="detail">
            <p>
              考试名称:<span>{{ examDetail.examName }}</span>
            </p>
            <p>
              考试日期:<span>{{ examDetail.examDate }}</span>
            </p>
            <p>
              考试时长:<span>{{ examDetail.consumingTime }}</span>
            </p>
          </div>
          <div class="count">
            <div class="icon">
              <img src="../../../assets/monitor/submitted.png" alt="" />
            </div>
            <div>
              <div>
                <span style="color: #4CE47A">{{ examDetail.submitNum }}</span>
                人
              </div>
              <p>已提交人数</p>
            </div>
          </div>
          <div class="count">
            <div class="icon">
              <img src="../../../assets/monitor/unSubmit.png" alt="" />
            </div>
            <div>
              <div>
                <span style="color: #FF5050">{{ examDetail.unSubmitNum }}</span>
                人
              </div>
              <p>未提交人数</p>
            </div>
          </div>
        </div>
      </div>
      <div class="yt-header-toolbar">
        <el-input v-model="keyword" placeholder="通过姓名模糊搜索" class="yt-search" @keydown.enter.native="searchKeyword">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="searchKeyword"></i>
        </el-input>
      </div>
    </div>
    <div class="yt-container">
      <div class="yt-content">
        <div class="title">
          <YTIcon :href="'#icon-kaoshizhong'"></YTIcon>
          考试中
        </div>
        <el-table :data="ongoingList" class="yt-table" height="calc(100% - 34px)">
          <el-table-column label="姓名" prop="userName" show-overflow-tooltip />
          <el-table-column label="开始时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ $formatTime(scope.row.startTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <div slot="empty" id="empty-slot-underway">
            <template v-if="!ongoingLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
            </template>
          </div>
        </el-table>
      </div>
      <div class="yt-content">
        <div class="title">
          <YTIcon :href="'#icon-yitijiao'"></YTIcon>
          已提交
        </div>
        <el-table :data="submittedList" class="yt-table" height="calc(100% - 34px)">
          <el-table-column label="姓名" prop="userName" show-overflow-tooltip />
          <el-table-column label="提交时间" show-overflow-tooltip>
            <template slot-scope="scope">{{ $formatTime(scope.row.submitTime, 'yyyy-MM-dd hh:mm') }}</template>
          </el-table-column>
          <div slot="empty" id="empty-slot-submitted">
            <template v-if="!submittedLoading">
              <YTIcon :href="'#icon-zanwushuju'" style="font-size: 100px"></YTIcon>
            </template>
          </div>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import YTIcon from '@components/common/YTIcon'
import examMonitorApi from '@api/examMonitor'
export default {
  name: 'MonitorDetail',
  components: { YTIcon },
  data() {
    return {
      examId: '',
      examDetail: {
        examName: '',
        examDate: '',
        consumingTime: '',
        submitNum: 0,
        unSubmitNum: 0
      },
      keyword: '',
      ongoingList: [],
      submittedList: [],
      ongoingListCopy: [],
      submittedListCopy: [],
      ongoingLoading: true,
      submittedLoading: true,
      timer: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.examId = vm.$handleParams('monitorDetailData').examId
      examMonitorApi.getDetail(vm.examId).then(res => {
        vm.examDetail = res.res
      })
    })
  },
  mounted() {
    this.refreshUserInfo()
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    refreshUserInfo() {
      this.getOngoingUserInfo()
      //定时器 没有查询的情况下3s刷新一次数据
      this.timer = setInterval(() => {
        this.getOngoingUserInfo()
      }, 4000)
    },
    getOngoingUserInfo() {
      examMonitorApi
        .getOngoingUserInfo(this.examId, this.ongoingList.length > 0 ? this.ongoingList[0].answerPaperId : null)
        .then(res => {
          this.ongoingList = res.res.concat(this.ongoingList)
          this.ongoingListCopy = this.ongoingList
          this.$forceUpdate()
        })
        .finally(() => {
          this.ongoingLoading = false
          this.getSubmittedUserInfo()
        })
    },
    getSubmittedUserInfo() {
      examMonitorApi
        .getSubmittedUserInfo(this.examId, this.submittedList.length > 0 ? this.submittedList[0].answerPaperId : '')
        .then(res => {
          res.res.forEach(item => {
            this.ongoingList.map((data, index) => {
              if (data.answerPaperId === item.answerPaperId) {
                this.ongoingList.splice(index, 1)
              }
            })
          })
          this.submittedList = res.res.concat(this.submittedList)
          this.submittedListCopy = this.submittedList
          this.$set(this.examDetail, 'submitNum', this.submittedList.length)
          this.$set(this.examDetail, 'unSubmitNum', this.ongoingList.length)
          this.$forceUpdate()
        })
        .finally(() => {
          this.submittedLoading = false
        })
    },
    searchKeyword() {
      if (this.keyword === '') {
        this.ongoingList = []
        this.submittedList = []
        this.refreshUserInfo()
        return
      }
      clearInterval(this.timer)
      this.timer = null
      this.ongoingList = this.ongoingListCopy.filter(item => {
        if (item.userName.includes(this.keyword)) {
          return item
        }
      })
      this.submittedList = this.submittedListCopy.filter(item => {
        if (item.userName.includes(this.keyword)) {
          return item
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../theme/variables';
.yt-header {
  height: 121px;
  margin-top: 7px;
}
.exam-detail-wrap {
  .flexStyle(flex, flex-start, center);
  padding-left: 40px;
  color: #000000;
  font-size: @default;
  .exam-detail {
    .flexStyle(flex, flex-start);
    height: 81px;
    .detail {
      height: 100%;
      width: 214px;
      .flexStyle(flex, space-between, flex-start);
      flex-direction: column;
      border-right: 1px solid #d8d8d8;
    }
    .count {
      .flexStyle(flex, center, center);
      width: 194px;
      height: 100%;
      &:last-child {
        border-left: 1px solid #d8d8d8;
      }
      .icon {
        height: 49px;
        width: 49px;
        margin-right: 19px;
      }
      span {
        font-size: 36px;
        font-weight: 700;
      }
    }
    p {
      font-weight: bold;
      max-width: 210px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      span {
        margin-left: 15px;
      }
    }
  }
}
.yt-container {
  display: flex;
  padding: 10px 10px 0;
  height: calc(100% - 148px);
  font-size: @default;
  .yt-content {
    padding-bottom: 0;
    &:last-child {
      margin-left: 20px;
    }
    .title {
      color: #448bff;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
</style>
